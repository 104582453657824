@import "reset";
@import "variables";
@import "mixin";
@import "common";

body {
	overflow-y: hidden;
	overflow-x: hidden;
}
body.ios.safari {
	overflow-y: auto;
}

// attention à bien mettre des chemins absolus pour éviter la recopie des polices
@font-face {
	font-family: "Calibri";
	src: url("/data/font/Calibri-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Calibri";
	src: url("/data/font/Calibri-Italic.ttf");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "NotoSansMono";
	src: url("/data/font/NotoSansMono-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "NotoSansMono";
	src: url("/data/font/NotoSansMono-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Quantico";
	src: url("/data/font/Quantico-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Quantico";
	src: url("/data/font/Quantico-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Lato";
	src: url("/data/font/Lato-Italic.ttf");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Lato";
	src: url("/data/font/Lato-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Lato";
	src: url("/data/font/Lato-Black.ttf");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/data/font/Montserrat-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/data/font/Montserrat-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Orbitron";
	src: url("/data/font/Orbitron-ExtraBold.ttf");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "VT323";
	src: url("/data/font/VT323-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

body {
	-webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
	-webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
	-webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
	user-select: none;

	background: #000000;
	font-family: sans-serif;
	font-weight: normal;
	font-size: 32px;
	color: #ffffff;

	height: 100%;
	width: 100%;
}

a {
	cursor: pointer;
	color: #cceaec;
	line-height: 1.2em;
}

b {
	font-weight: bold;
}

i {
	font-style: italic;
}

h2,
h3,
h4 {
	font-weight: bold;
	margin: 0.5em 0;
	line-height: 1.2em;
}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1.3em;
}
h4 {
	font-size: 1.1em;
}

ul
{
	margin: 0.5em 0;
	li {     
		list-style: disc;
		margin-left: 1em;
	}
}

.noevents {
	pointer-events: none;
}

// personnalisation des scrollbars
@import "~overlayscrollbars/css/OverlayScrollbars.css";

.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-light > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
	background: #fbd37e;
	-webkit-box-shadow: rgb(251 211 126 / 20%) 0px 0px 10px 5px;
	box-shadow: rgb(251 211 126 / 20%) 0px 0px 10px 5px;
	max-height: 100px;
}

.os-theme-light > .os-scrollbar > .os-scrollbar-track {
	background: rgba(0, 0, 0, 0.3);
}

.os-theme-light > .os-scrollbar-vertical {
	bottom: 10px;
	width: 15px;
	overflow: hidden;
}
