@import "variables";
@import "mixin";

.yellow-light {
	text-shadow: 0px 0px 0.4em rgba(251,211,126,1);
}

.white-light {
	text-shadow: 0px 0px 0.4em rgba(255,255,255,1);
}

.green-light {
	text-shadow: 0px 0px 0.4em rgba(193, 243, 95, 1);
}

.white {
    color: white;
}